import React, { useState, useEffect } from "react";
import axios from "axios";
import { COMPANY_GET_EMPLOYEES } from "../../config/api";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import MainLayout from "../../components/MainLayout";
import Loader from "../../components/Loader";
import { Link } from "react-router-dom";
import { showToast } from "../../components/global/showToast";

export default function Agents() {
  const [agents, setAgents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchAgents = async () => {
      try {
        const companyId = localStorage.getItem("id");
        if (!companyId) {
          throw new Error("Company ID not found in local storage");
        }
        const response = await axios.get(COMPANY_GET_EMPLOYEES(companyId));
        if (!response.data.error) {
          setAgents(response.data.meta.employees);
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }

      } catch (error) {
        showToast("error", error.message);
        setError("Error fetching agents: " + error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  if (error) return <Typography color="error">{error}</Typography>;

  const roleType = localStorage.getItem("role");
  console.log(roleType);

  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row">
          {loading ? (
            <Loader />
          ) : (
            <div className="col-md-12">
              <div className="d-flex justify-content-between my-2">
                <h5>{roleType === "parent" ? "Agents" : "Users"}</h5>
                <Link
                  to={
                    roleType === "parent"
                      ? "/company/create-agent"
                      : "/child/create-agent"
                  }
                >
                  <button className="btn btn-primary bg-primary">
                    {roleType === "parent" ? "Create Agents" : "Create Users"}
                  </button>
                </Link>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Phone Number</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Designation</TableCell>
                      <TableCell>Address</TableCell>
                      {/* <TableCell>Company</TableCell> */}
                    </TableRow>
                  </TableHead>
                  {agents.length > 0 ? (
                    <TableBody>
                      {agents
                        .filter((agent) => agent.designation !== "Owner")
                        .map((agent, index) => (
                          <TableRow key={agent.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{agent.name}</TableCell>
                            <TableCell>{agent.email}</TableCell>
                            <TableCell>{agent.phoneNumber}</TableCell>
                            <TableCell>{agent.role}</TableCell>
                            <TableCell>{agent.designation}</TableCell>
                            <TableCell>{agent.address}</TableCell>
                            {/* <TableCell>{agent.company.name}</TableCell> */}
                          </TableRow>
                        ))}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableCell colSpan={6} className="text-center">
                        {roleType === "parent"
                          ? "No Agents Found"
                          : "No Users Found"}
                      </TableCell>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      </div>
    </MainLayout>
  );
}
