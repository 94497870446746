import { configureStore } from '@reduxjs/toolkit';
import ticketsReducer from "./tickets/ticketsSlice";
const store = configureStore({
  reducer: {
    tickets: ticketsReducer, // Add your slice reducer here
  },
  // Redux Toolkit automatically includes thunk middleware, so no need to manually add it.
});

export default store;
