import React, { useState } from "react";
import MainLayout from "../../components/MainLayout";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function AdminNewTicket() {
  const [value, setValue] = useState("");
  const groups = [
    { label: "Affy Cloud IT Solution" },
    { label: "Stockmint" },
    { label: "Eleganza" },
    { label: "Colab Tools" },
    { label: "Panatronix" },
  ];

  const agents = [
    { label: "Arsalan Khan" },
    { label: "Arbaz" },
    { label: "Rizwan" },
    { label: "Talib" },
    { label: "Mohammad Anas Shaikh" },
  ];

  const type = [
    { label: "Incident" },
    { label: "Query" },
    { label: "Change Request" },
    { label: "Training" },
    { label: "Project" },
    { label: "Service Request" },
  ];
  const status = [
    { label: "Open" },
    { label: "In Process" },
    { label: "Pending" },
    { label: "Awaiting on Customer" },
    { label: "Resolved" },
    { label: "Reopen" },
    { label: "Closed" },
  ];

  const priority = [
    { label: "Low" },
    { label: "Medium" },
    { label: "High" },
    { label: "Urgent" },
  ];

  const product = [{ label: "Affy Cloud IT Solution" }];
  const modules = [
    { label: "Frontend" },
    { label: "Backend" },
    { label: "Server" },
  ];

  const toolbar = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];
  return (
    <MainLayout>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3">
              <h5>Create New Ticket</h5>
              <form>
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={groups}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Group" />
                  )}
                />
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={agents}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Agent" />
                  )}
                />

                <TextField
                  id="outlined-basic"
                  label="Subject"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />

                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={agents}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Contact" />
                  )}
                />

                <TextField
                  id="outlined-basic"
                  label="Raised by"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />

                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={type}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Type" />
                  )}
                />

                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={status}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" />
                  )}
                />
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={priority}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Priority" />
                  )}
                />
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={product}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Product" />
                  )}
                />
                <Autocomplete
                  disableClearable
                  disablePortal
                  id="combo-box-demo"
                  options={modules}
                  sx={{ marginTop: 2, marginBottom: 2 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Modules" />
                  )}
                />

                <TextField
                  id="outlined-basic"
                  label="Estimated Hours"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
                <TextField
                  id="outlined-basic"
                  label="Approved  Hours"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
                <TextField
                  id="outlined-basic"
                  label="Actual Support Hours"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />
                <div className="my-2">
                  <label>Description</label>
                  <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={setValue}
                    modules={toolbar}
                    formats={formats}
                  />
                </div>

                <TextField
                  id="outlined-basic"
                  label="Business Impact"
                  variant="outlined"
                  fullWidth
                  sx={{ marginTop: 2, marginBottom: 2 }}
                />

                <div className="d-flex justify-content-end">
                  <button className="btn mr-1">Cancel</button>
                  <button className="btn btn-primary mr-1">Create</button>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="card p-3"></div>
          </div> */}
        </div>
      </div>
    </MainLayout>
  );
}
