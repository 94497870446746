import React, { useState, useEffect } from "react";
import MainLayout from "../../components/MainLayout";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import {
  assignTicketToAgent,
  changeTicketPriority,
  changeTicketStatus,
  fetchCompanyEmployees,
  fetchTicket,
  fetchTicketMessages,
  sendMessage,
  changeHours,
  assignChildEmployee,
} from "../../config/webclient";
import TicketMessageLog from "../../components/TicketMessageLog";
import { Helmet } from "react-helmet";
import TimeAgo from "../../components/TimeAgo";
import DateTime from "../../components/DateTime";
import Loader from "../../components/Loader";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import { Snackbar, Alert } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { COMPANY_GET_EMPLOYEES } from "../../config/api";
import axios from "axios";

export default function ChatLogs() {
  const [message, setMessage] = useState("");
  const [attachment, setAttachment] = useState(null);
  const [ticketDetails, setTicketDetails] = useState({});
  const [ticketDetilsLoaded, setTicketDetailsLoaded] = useState(false);
  const [ticketLogs, setTicketLogs] = useState([]);
  const [logsLoaded, setLogsLoaded] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);

  const [employees, setemployees] = useState([]);

  const [childEmployees, setchildEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchAgents = async () => {
      try {
        const companyId = localStorage.getItem("id");
        if (!companyId) {
          throw new Error("Company ID not found in local storage");
        }
        const response = await axios.get(COMPANY_GET_EMPLOYEES(companyId));
        setchildEmployees(response.data.meta.employees);
      } catch (error) {
        setError("Error fetching agents: " + error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  const [employeesLoaded, setEmployeesLoaded] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const showSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(file.type)) {
        showSnackbar(
          "Invalid file type. Only JPG, PNG, and PDF files are allowed.",
          "error"
        );
        return;
      }
      setAttachment(file);
    }
  };
  const { id } = useParams();
  const ticketId = id;
  var role = localStorage.getItem("role");

  const loadEmployees = async () => {
    var companyId;
    if (role === "parent") {
      companyId = localStorage.getItem("id");
    } else if (role === "child") {
      companyId = localStorage.getItem("parentId");
    } else if (role === "employee") {
      companyId = localStorage.getItem("companyId");
    } else {
      return;
    }
    if (companyId > 0) {
      await fetchCompanyEmployees(companyId)
        .then((response) => {
          console.log("agent", response.data);
          setemployees(response.data.meta.employees);
          setEmployeesLoaded(true);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changePriority = async (priority) => {
    if (ticketDetilsLoaded) {
      const ticketId = ticketDetails.id;
      await changeTicketPriority({
        ticketId: ticketId,
        priority: priority,
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changeStatus = async (status) => {
    if (ticketDetilsLoaded) {
      const ticketId = ticketDetails.id;
      await changeTicketStatus({
        ticketId: ticketId,
        status: status,
      })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const changeAgent = async (agentId) => {
    const ticketId = ticketDetails.id;
    console.log(agentId);
    await assignTicketToAgent({
      ticketId: ticketId,
      agentId: agentId,
    })
      .then((response) => {
        console.log(response.log);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeChildEmployee = async (employeeId, employeeName) => {
    const ticketId = ticketDetails.id;
    await assignChildEmployee(ticketId, {
      id: employeeId,
      name: employeeName,
    })
      .then((response) => {
        console.log("Response:", response);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const onAgentChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changeAgent(event.target.value);
      fetchTicketDetails(id);
      showSnackbar("Assignee Updated Successfully", "success");
    }
  };

  const onChildEmployeeChange = async (event) => {
    const selectedValue = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text;
    event.preventDefault();

    if (setTicketDetailsLoaded) {
      await changeChildEmployee(selectedValue, selectedText); // Call the function with event.target.value (agentId)
      fetchTicketDetails(id); // Fetch updated details
      showSnackbar("Employee Updated Successfully", "success"); // Show success message
    }
  };

  const onPriorityChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changePriority(event.target.value);
      fetchTicketDetails(id);
      showSnackbar("Priority Updated Successfully", "success");
    }
  };

  const onStatusChange = async (event) => {
    event.preventDefault();
    if (setTicketDetailsLoaded) {
      await changeStatus(event.target.value);
      fetchTicketDetails(id);
      showSnackbar("Status Updated Successfully", "success");
    }
  };

  const sendTicketReply = async (event) => {
    setReplyLoading(true);
    event.preventDefault();
    if (!message.trim()) {
      showSnackbar("Message cannot be blank", "error");
      setReplyLoading(false);
      return;
    }
    const role = localStorage.getItem("role");
    let employeeId,
      ticketId = id;

    // Determine employeeId based on the role
    if (
      role === "manager" ||
      role === "user" ||
      role === "employee" ||
      (role === "agent" && message.length !== 0)
    ) {
      employeeId = localStorage.getItem("id");
    } else if (role === "parent" || role === "child") {
      employeeId = localStorage.getItem("ownerId");
    } else {
      console.error("Invalid role or message is empty.");
      return;
    }

    if (!employeeId || !ticketId || !message || message.length === 0) {
      console.error("Missing required fields.");
      return;
    }

    try {
      // Create FormData
      const formData = new FormData();
      formData.append("employeeId", employeeId);
      formData.append("ticketId", ticketId);
      formData.append("message", message);
      formData.append("attachment", attachment);

      // Log FormData entries for debugging
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      const response = await sendMessage(formData);
      console.log(response);
      if (response) {
        fetchTicketLogs();
        setMessage("");
        setAttachment(null);
        setReplyLoading(false);
      }
    } catch (error) {
      console.error(
        "Error sending message:",
        error.response ? error.response.data : error.message
      );
      setReplyLoading(false);
    }
  };

  const fetchTicketLogs = async () => {
    try {
      await fetchTicketMessages(id).then((response) => {
        const logs = response.data;
        setTicketLogs(
          logs.sort((b, a) => new Date(b.createdAt) - new Date(a.createdAt))
        );
        setLogsLoaded(true);
        // console.log(logs);
      });
    } catch (error) {
      setLogsLoaded(false);
      console.log(error);
    }
  };

  /* Function to autorefresh notifications logs. */
  useEffect(() => {
    const intervelId = setInterval(() => {
      fetchTicketLogs();
    }, 2000);
    return () => clearInterval(intervelId);
  }, []);

  const fetchTicketDetails = async (id) => {
    try {
      await fetchTicket(id).then((response) => {
        const logs = response.data;
        console.log(logs);
        setTicketDetails(response.data);
        setTicketDetailsLoaded(true);
      });
    } catch (error) {
      setTicketDetailsLoaded(false);
      setLogsLoaded(false);
      console.log(error);
    }
  };

  useEffect(() => {
    fetchTicketLogs();
    fetchTicketDetails(ticketId);
    loadEmployees();
  }, []);

  const toolbar = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const hasAuthority = () => {
    if (ticketDetilsLoaded) {
      var role = localStorage.getItem("role");
      var id = localStorage.getItem("id");
      switch (role) {
        case "parent":
          return true;

        case "employee":
          return true;

        case "child":
          return false;

        case ticketDetails.project.managerId == id:
          return true;

        default:
          return false;
      }
    }
  };

  const [editingField, setEditingField] = useState(null);
  const [formValues, setFormValues] = useState({
    estimatedHours: ticketDetails.estimatedHours,
    approvedTime: ticketDetails.approvedTime,
    actualSupportHours: ticketDetails.actualSupportHours,
    actualCompletionDate: ticketDetails.actualCompletionDate,
    estimatedCompletionDate: ticketDetails.estimatedCompletionDate,
  });

  // Handle the change for any of the fields (estimated, approved, actual support hours)
  const handleFieldChange = (field, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const toggleEdit = async (fieldName) => {
    // Define a mapping for field names to display labels
    const fieldLabels = {
      estimatedHours: "Estimated Hours",
      approvedTime: "Approved Time",
      actualSupportHours: "Actual Support Hours",
      actualCompletionDate: "Actual Completion Date",
      estimatedCompletionDate: "Estimated Completion Date",
    };

    if (editingField === fieldName) {
      try {
        const data = {
          [fieldName]: formValues[fieldName], // Only send the specific field
        };

        // Make the API call using the ticketDetails.id
        await changeHours(ticketDetails.id, data);

        // Show the Snackbar with a custom label for the updated field
        showSnackbar(
          `Updated ${fieldLabels[fieldName]} to ${formValues[fieldName]} hours`
        );
      } catch (error) {
        // Optionally handle error
        showSnackbar(
          `Failed to update ${fieldLabels[fieldName]}. Please try again.`,
          "error"
        );
      }

      // Exit edit mode
      setEditingField(null);
    } else {
      // Enter edit mode for this field
      setEditingField(fieldName);
    }
  };

  // Function to render a field with editability based on the state

  useEffect(() => {
    if (ticketDetails) {
      setFormValues({
        estimatedHours: ticketDetails.estimatedHours || "",
        approvedTime: ticketDetails.approvedTime || "",
        actualSupportHours: ticketDetails.actualSupportHours || "",
        actualCompletionDate: ticketDetails.actualCompletionDate || "",
        estimatedCompletionDate: ticketDetails.estimatedCompletionDate || "",
      });
    }
  }, [ticketDetails]);
  const renderField = (label, fieldName) => {
    const isEditable =
      (role !== "user" &&
        role !== "child" &&
        fieldName === "actualSupportHours") || // Other roles can edit actualSupportHours
      ((role === "user" || role === "child") &&
        (fieldName === "estimatedHours" ||
          fieldName === "approvedTime" ||
          fieldName === "estimatedCompletionDate")) || // User and child can edit these fields
      (fieldName === "actualCompletionDate" &&
        role !== "user" &&
        role !== "child"); // Other roles can edit actualCompletionDate

    return (
      <div className="my-2">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <p className="f-12 mb-0">{label}</p>
            {editingField === fieldName ? (
              fieldName === "estimatedCompletionDate" ||
              fieldName === "actualCompletionDate" ? (
                <input
                  type="date"
                  className="form-control"
                  value={formValues[fieldName]}
                  onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                  disabled={!isEditable} // Disable input if not editable
                />
              ) : (
                <input
                  type="number"
                  className="form-control"
                  value={formValues[fieldName]}
                  onChange={(e) => handleFieldChange(fieldName, e.target.value)}
                  disabled={!isEditable} // Disable input if not editable
                />
              )
            ) : (
              <p className="mb-0 fw-500 f-12">
                {formValues[fieldName] ? formValues[fieldName] : "N/A"}
              </p>
            )}
          </div>
          {isEditable && (
            <div>
              <p className="f-12 mb-0">
                <i
                  className={`fa ${
                    editingField === fieldName ? "fa-check" : "fa-pencil"
                  }`}
                  onClick={() => toggleEdit(fieldName)}
                  style={{ cursor: "pointer" }}
                />
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <MainLayout>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Helmet>
        <title>{ticketDetails.title}</title>
      </Helmet>
      <div className="container-fluid">
        {ticketDetilsLoaded ? (
          <div className="row">
            <div className="col-md-6 height-75">
              <div className="card p-3">
                <div>
                  {logsLoaded && ticketDetilsLoaded ? (
                    <div>
                      <h5>
                        {ticketDetails.title} #{ticketDetails.id}
                      </h5>
                      <hr />
                      <p
                        className="mb-0"
                        dangerouslySetInnerHTML={{
                          __html: ticketDetails.description,
                        }}
                      />
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {logsLoaded
                  ? ticketLogs.map((message) => (
                      <TicketMessageLog
                        props={{
                          senderName: message.employee.name,
                          timestamp: message.createdAt,
                          message: message.message,
                          timeStamp: message.createdAt,
                          attachment: message.attachmentUrl,
                        }}
                      />
                    ))
                  : "Loading"}
                {/* {role !== "parent" && role !== "child" ? ( */}
                {!ticketDetails.agent ? (
                  <p className="text-danger fw-500">
                    Agent has not been assigned yet.
                  </p>
                ) : (
                  ticketDetilsLoaded &&
                  ticketDetails.status != "closed" && (
                    <div className="my-3">
                      <form>
                        <ReactQuill
                          theme="snow"
                          value={message}
                          onChange={setMessage}
                          modules={toolbar}
                          formats={formats}
                        />
                        <input
                          className="form-control my-2"
                          type="file"
                          placeholder="Add Attachment"
                          accept="image/jpeg, image/png, application/pdf"
                          onChange={handleFileChange}
                        />
                        <button
                          className="btn btn-primary my-1 bg-primary w-110 f-14 d-flex align-items-center justify-content-center"
                          onClick={(event) => sendTicketReply(event)}
                          disabled={replyLoading}
                        >
                          {/* Reply */}
                          {replyLoading ? "Sending..." : "Send "}
                          <SendIcon sx={{ fontSize: 14, marginLeft: 1 }} />
                        </button>
                      </form>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="col-md-3 height-75">
              <div className="card p-3">
                {ticketDetails ? (
                  <>
                    <h6 className="mb-0 text-capitalize">
                      {/* {ticketDetails.status ? ticketDetails.status : "Loading"} */}
                      {ticketDetails.type ? ticketDetails.type : "Loading"}
                    </h6>
                    <p className="f-13 mb-0">
                      <TimeAgo createdAt={ticketDetails.createdAt} />
                      {" | "}
                      <DateTime createdAt={ticketDetails.createdAt} />
                    </p>
                    {/* <p className="f-13 mb-0 fw-500">Last Updated</p>
                    <p className="f-13 mb-0">
                      {ticketDetails.updatedAt ? (
                        <DateTime createdAt={ticketDetails.updatedAt} />
                      ) : (
                        "Loading"
                      )}
                    </p> */}
                    <div className="my-2">
                      <p className="f-12 mb-0">Group</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.company.name
                          ? ticketDetails.company.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Project</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.project.name
                          ? ticketDetails.project.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Module</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.projectModule.name
                          ? ticketDetails.projectModule.name
                          : "Loading"}
                      </p>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Priority</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onPriorityChange(event)}
                            value={ticketDetails.priority || ""}
                          >
                            <option>Low</option>
                            <option>Medium</option>
                            <option>High</option>
                            <option>Urgent</option>
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.priority
                            ? ticketDetails.priority
                            : "Loading"}
                        </p>
                      )}
                    </div>

                    <div className="my-2">
                      <p className="f-12 mb-0">Status</p>
                      <select
                        className="form-select no-box-shadow f-12"
                        onChange={(event) => onStatusChange(event)}
                        value={
                          ticketDetails.status
                            ? ticketDetails.status.toLowerCase()
                            : ""
                        }
                      >
                        <option
                          value="open"
                          disabled={role === "user" || role === "child"}
                        >
                          Open
                        </option>
                        <option value="resolved">Resolved</option>
                        <option
                          value="reopen"
                          disabled={role === "user" || role === "child"}
                        >
                          Reopen
                        </option>
                        <option value="in process">In Process</option>
                        <option value="pending">Pending</option>
                        <option
                          value="awaiting on customer"
                          disabled={role === "user" || role === "child"}
                        >
                          Awaiting on Customer
                        </option>
                        <option
                          value="awaiting on third party"
                          disabled={role === "user" || role === "child"}
                        >
                          Awaiting on Third Party
                        </option>
                        <option
                          value="closed"
                          disabled={role === "user" || role === "child"}
                        >
                          Closed
                        </option>
                      </select>
                    </div>
                    <div className="my-2">
                      <p className="f-12 mb-0">Assignee</p>
                      {role === "parent" || role === "employee" ? (
                        hasAuthority() &&
                        employeesLoaded && (
                          <select
                            className="form-select no-box-shadow f-12"
                            onChange={(event) => onAgentChange(event)}
                            value={ticketDetails.agent?.id || ""}
                          >
                            {!ticketDetails.agent && (
                              <option value="">Unassigned</option>
                            )}
                            {employees
                              .filter(
                                (employee) => employee.designation !== "Owner"
                              )
                              .map((employee) => (
                                <option key={employee.id} value={employee.id}>
                                  {employee.name}
                                </option>
                              ))}
                          </select>
                        )
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.agent?.name || "Not Assigned"}{" "}
                          {/* Show UnAssigned if no agent */}
                        </p>
                      )}
                    </div>

                    {/* Employee Assign */}
                    {/* <div className="my-2">
                      <p className="f-12 mb-0">Raised By</p>
                      {role === "child" ? (
                        <select
                          className="form-select no-box-shadow f-12"
                          onChange={(event) => onChildEmployeeChange(event)}
                          value={ticketDetails.employee?.id || ""}
                        >
                          {!ticketDetails.employee?.id && (
                            <option value="">Unassigned</option>
                          )}
                          {childEmployees
                            .filter(
                              (employee) => employee.designation !== "Owner"
                            )
                            .map((employee) => (
                              <option key={employee.id} value={employee.id}>
                                {employee.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.employee?.name || "Not Assigned"}{" "}
                        </p>
                      )}
                    </div> */}
                    <div className="my-2">
                      <p className="f-12 mb-0">Raised By</p>
                      {role === "child" ? (
                        <select
                          className="form-select no-box-shadow f-12"
                          onChange={(event) => onChildEmployeeChange(event)}
                          value={ticketDetails.employee?.id || ""}
                        >
                          {/* If no employee is assigned, show "Unassigned" as the first option */}
                          <option value="">
                            {!ticketDetails.employee?.id
                              ? "Unassigned"
                              : "Select"}
                          </option>

                          {/* Map through the list of employees */}
                          {childEmployees
                            .filter(
                              (employee) => employee.designation !== "Owner"
                            )
                            .map((employee) => (
                              <option key={employee.id} value={employee.id}>
                                {employee.id === ticketDetails.employee?.id
                                  ? employee.name
                                  : employee.name}
                              </option>
                            ))}
                        </select>
                      ) : (
                        <p className="mb-0 fw-500 f-12">
                          {ticketDetails.employee?.name || "Not Assigned"}
                        </p>
                      )}
                    </div>

                    {/* Employee Assign */}

                    <div>
                      {renderField("Estimated Hours", "estimatedHours")}
                      {renderField("Approved Hours", "approvedTime")}
                      {renderField(
                        "Actual Support Hours",
                        "actualSupportHours"
                      )}
                      <div className="my-2">
                        <p className="f-12 mb-0">
                          Difference in Actual & Approved Hours
                        </p>
                        <p className="mb-0 fw-500 f-12">
                          {formValues.actualSupportHours != null &&
                          formValues.approvedTime != null
                            ? Math.abs(
                                formValues.actualSupportHours -
                                  formValues.approvedTime
                              ).toString()
                            : "N/A"}
                        </p>
                      </div>

                      {renderField(
                        "Estimated Completion Date",
                        "estimatedCompletionDate"
                      )}
                      {renderField(
                        "Actual Completion Date",
                        "actualCompletionDate"
                      )}
                    </div>

                    <div className="my-2">
                      <p className="f-12 mb-0">Business Impact</p>
                      <p className="mb-0 fw-500 f-12">
                        {ticketDetails.businessImpact !== null
                          ? ticketDetails.businessImpact
                          : "None"}
                      </p>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
            <div className="col-md-3 height-75">
              <div className="card p-3">
                <h6 className="mb-0 text-capitalize">Timeline</h6>
                <Timeline position="alternate">
                  {ticketDetails && ticketDetails.timeline
                    ? ticketDetails.timeline.map((item, index) => (
                        <TimelineItem key={index}>
                          <TimelineSeparator>
                            <TimelineDot />
                            {/* Only show connector if it's not the last item */}
                            {index < ticketDetails.timeline.length - 1 && (
                              <TimelineConnector />
                            )}
                          </TimelineSeparator>
                          <TimelineContent>
                            <span className="f-12">{item.status}</span>
                          </TimelineContent>
                        </TimelineItem>
                      ))
                    : ""}
                </Timeline>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </MainLayout>
  );
}
