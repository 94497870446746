// import React, { useState } from "react";
// import TextField from "@mui/material/TextField";
// import Visibility from "@mui/icons-material/Visibility";
// import VisibilityOff from "@mui/icons-material/VisibilityOff";
// import IconButton from "@mui/material/IconButton";
// import OutlinedInput from "@mui/material/OutlinedInput";
// import InputLabel from "@mui/material/InputLabel";
// import InputAdornment from "@mui/material/InputAdornment";
// import FormControl from "@mui/material/FormControl";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Select from "@mui/material/Select";
// import MenuItem from "@mui/material/MenuItem";
// import { loginService } from "../config/services";
// import { jwtDecode } from "jwt-decode";
// import { showToast } from "../components/global/showToast";

// export default function Login() {
//   const [showPassword, setShowPassword] = useState(false);
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [error, setError] = useState("");
//   const [type, setType] = useState("company");

//   const handleClickShowPassword = () => setShowPassword((show) => !show);

//   const handleMouseDownPassword = (event) => {
//     event.preventDefault();
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (email === "superadmin@gmail.com" && password === "123456") {
//       localStorage.setItem("role", "superadmin");
//       window.location.href = "/superadmin/dashboard";
//       return;
//     }

//     try {
//       const response = await loginService(email, password, type);
//       if (!error) {
//         const token = response.meta.response;
//         localStorage.setItem("token", token);
//         const decodedToken = jwtDecode(token);
//         localStorage.setItem("role", decodedToken.claims.role);
//         localStorage.setItem("id", decodedToken.claims.id);
//         localStorage.setItem("name", decodedToken.claims.name);
//         if (
//           decodedToken.claims.role === "employee" ||
//           decodedToken.claims.role === "user" ||
//           decodedToken.claims.role === "agent" ||
//           decodedToken.claims.role === "manager"
//         ) {
//           localStorage.setItem("companyId", decodedToken.claims.company.id);
//           localStorage.setItem("companyName", decodedToken.claims.company.name);
//         }

//         if (decodedToken.claims.role === "parent") {
//           localStorage.setItem("ownerId", decodedToken.claims.ownerId);
//           window.location.href = "/company/dashboard";
//         } else if (decodedToken.claims.role === "employee") {
//           window.location.href = "/employee/dashboard";
//         } else if (decodedToken.claims.role === "child") {
//           localStorage.setItem("parentId", decodedToken.claims.parentId);
//           localStorage.setItem("ownerId", decodedToken.claims.ownerId);
//           window.location.href = "/child/dashboard";
//         } else if (decodedToken.claims.role === "user") {
//           window.location.href = "/user/dashboard";
//         } else if (decodedToken.claims.role === "manager") {
//         window.location.href = "/employee/dashboard";
//         }
//       } else if(error){
//         showToast("error", response.message)
//       }
//     } catch (error) {
//       showToast("error", error.message);
//       console.log(error.message);
//       setError(error.message);
//       console.error("Login error:", error.message);
//     }
//   };

//   return (
//     <div className="container my-5">
//       <div className="row justify-content-center my-2">
//         <div className="col-md-6">
//           <div className="card p-5">
//             <h5>Login to the support portal</h5>
//             <p className="mb-0 f-12">Enter the details below</p>
//             <form onSubmit={handleSubmit}>
//               <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
//                 <InputLabel id="user-type-label">User Type</InputLabel>
//                 <Select
//                   labelId="user-type-label"
//                   id="user-type"
//                   value={type}
//                   onChange={(e) => setType(e.target.value)}
//                   label="User Type"
//                 >
//                   <MenuItem value="company">Company</MenuItem>
//                   <MenuItem value="employee">Agent/Employee</MenuItem>
//                 </Select>
//               </FormControl>
//               <TextField
//                 id="email"
//                 label="Your email address"
//                 variant="outlined"
//                 fullWidth
//                 sx={{ marginTop: 2 }}
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//                 required
//                 type="email"
//               />

//               <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
//                 <InputLabel htmlFor="outlined-adornment-password">
//                   Password
//                 </InputLabel>
//                 <OutlinedInput
//                   id="outlined-adornment-password"
//                   type={showPassword ? "text" : "password"}
//                   value={password}
//                   required
//                   onChange={(e) => setPassword(e.target.value)}
//                   endAdornment={
//                     <InputAdornment position="end">
//                       <IconButton
//                         aria-label="toggle password visibility"
//                         onClick={handleClickShowPassword}
//                         onMouseDown={handleMouseDownPassword}
//                         edge="end"
//                       >
//                         {showPassword ? <VisibilityOff /> : <Visibility />}
//                       </IconButton>
//                     </InputAdornment>
//                   }
//                   label="Password"
//                 />
//                 <FormControlLabel
//                   control={<Checkbox color="success" />}
//                   label="Remember me on this computer"
//                 />
//                 <button type="button" className="btn w-content">
//                   Forget your Password?
//                 </button>
//                 <button
//                   type="submit"
//                   className="btn btn-success w-content my-2"
//                 >
//                   Login
//                 </button>
//               </FormControl>
//             </form>
//             {error && <p className="text-danger">{error}</p>}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { loginService } from "../config/services";
import { jwtDecode } from "jwt-decode";
import { showToast } from "../components/global/showToast";

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [type, setType] = useState("company");

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(""); // Clear previous error

    if (!email || !password) {
      setError("Email and password are required");
      return;
    }

    if (email === "superadmin@gmail.com" && password === "123456") {
      localStorage.setItem("role", "superadmin");
      window.location.href = "/superadmin/dashboard";
      return;
    }

    try {
      const response = await loginService(email, password, type);
      const token = response.meta?.response;

      if (token) {
        localStorage.setItem("token", token);
        const decodedToken = jwtDecode(token);

        localStorage.setItem("role", decodedToken.claims.role);
        localStorage.setItem("id", decodedToken.claims.id);
        localStorage.setItem("name", decodedToken.claims.name);

        if (
          decodedToken.claims.role === "employee" ||
          decodedToken.claims.role === "user" ||
          decodedToken.claims.role === "agent" ||
          decodedToken.claims.role === "manager"
        ) {
          localStorage.setItem("companyId", decodedToken.claims.company.id);
          localStorage.setItem("companyName", decodedToken.claims.company.name);
        }

        switch (decodedToken.claims.role) {
          case "parent":
            localStorage.setItem("ownerId", decodedToken.claims.ownerId);
            window.location.href = "/company/dashboard";
            break;
          case "employee":
          case "manager":
            window.location.href = "/employee/dashboard";
            break;
          case "child":
            localStorage.setItem("parentId", decodedToken.claims.parentId);
            localStorage.setItem("ownerId", decodedToken.claims.ownerId);
            window.location.href = "/child/dashboard";
            break;
          case "user":
            window.location.href = "/user/dashboard";
            break;
          default:
            setError("Invalid role");
        }
      } else {
        throw new Error(response.message || "Login failed. Please try again.");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred.";
      setError(errorMessage);
      showToast("error", errorMessage);
      console.error("Login error:", errorMessage);
    }
  };

  return (
    <div className="container my-5">
      <div className="row justify-content-center my-2">
        <div className="col-md-6">
          <div className="card p-5">
            <h5>Login to the support portal</h5>
            <p className="mb-0 f-12">Enter the details below</p>
            <form onSubmit={handleSubmit}>
              <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
                <InputLabel id="user-type-label">User Type</InputLabel>
                <Select
                  labelId="user-type-label"
                  id="user-type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  label="User Type"
                >
                  <MenuItem value="company">Company</MenuItem>
                  <MenuItem value="employee">Agent/Employee</MenuItem>
                </Select>
              </FormControl>
              <TextField
                id="email"
                label="Your email address"
                variant="outlined"
                fullWidth
                sx={{ marginTop: 2 }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                type="email"
              />
              <FormControl fullWidth variant="outlined" sx={{ marginTop: 2 }}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <FormControlLabel
                control={<Checkbox color="success" />}
                label="Remember me on this computer"
              />
              <br />
              <button type="submit" className="btn btn-success w-content my-2">
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
