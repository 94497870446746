import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import MainLayout from "../../components/MainLayout";
import { PROJECT_CREATE } from "../../config/api";
import axios from "axios";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { showToast } from "../../components/global/showToast";

export default function CreateProject() {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const companyId = localStorage.getItem("id");

    if (!companyId) {
      handleSnackbar("Company ID is missing in local storage", "error");
      return;
    }

    try {
      const response = await axios.post(PROJECT_CREATE, {
        ...formData,
        companyId: Number(companyId), // Ensure companyId is a number
      });

      if (!response.error) {
        showToast("success", response.data.message);
        setFormData({
          name: "",
          description: "",
        });
      } else if (response.error) {
        showToast("error", response.data.message);
      }
    } catch (error) {
      showToast("error", error.response.data.message);
      console.error("Error creating project:", error);
      handleSnackbar("Error creating project", "error");
    }
  };

  return (
    <MainLayout>
      <div className="container my-2">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-5">
              <h5>Create Project</h5>
              <p className="f-12">Enter the details below</p>
              <form className="row" onSubmit={handleSubmit}>
                <div className="col-md-6">
                  <TextField
                    id="name"
                    label="Project Name"
                    variant="outlined"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="description"
                    label="Description"
                    variant="outlined"
                    fullWidth
                    value={formData.description}
                    onChange={handleChange}
                    sx={{ marginTop: 1, marginBottom: 1 }}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <button type="submit" className="btn btn-success w-content">
                    Create
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </MainLayout>
  );
}
