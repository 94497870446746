import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchTicketsForAgent,
  fetchTicketsForEmployee,
  fetchCompanyTickets,
  fetchChildCompanyTicketsById,
} from "../config/webclient";
import { MANAGER_TICKETS } from "../config/api";
import { showToast } from "../components/global/showToast";

export const fetchTicketsForRole = createAsyncThunk(
  "tickets/fetchTicketsForRole",
  async ({ id, role }, { rejectWithValue }) => {
    try {
      if (role === "user") {
        const response = await fetchTicketsForEmployee(id);
        if (!response.data.error) {
          console.log(response);
          return response.data.meta.tickets;
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }
      } else if (["agent", "manager", "employee"].includes(role)) {
        // const agentTicketsResponse = await fetchTicketsForAgent(id);
        // const agentTickets = agentTicketsResponse.data.meta.tickets;
        // const managerTicketResponse = await fetch(`${MANAGER_TICKETS}/${id}`);
        // if (managerTicketResponse.status === 404) {
        //   return agentTickets;
        // }

        // if (!managerTicketResponse.ok) {
        //   throw new Error("Error fetching manager tickets");
        // }

        // const managerTickets = await managerTicketResponse.json();
        // console.log(managerTickets.meta.tickets)

        // const combinedTickets = [...agentTickets, ...managerTickets.meta.tickets];
        // const uniqueTickets = combinedTickets.reduce((acc, current) => {
        //   const exists = acc.find((item) => item.id === current.id);
        //   if (!exists) {
        //     return acc.concat([current]);
        //   }
        //   return acc;
        // }, []);

        // return uniqueTickets;
        try {
          // Initialize empty arrays for tickets
          let agentTickets = [];
          let managerTickets = [];
        
          // Fetch agent tickets
          try {
            const agentTicketsResponse = await fetchTicketsForAgent(id);
            agentTickets = agentTicketsResponse?.data?.meta?.tickets || [];
            console.log("Agent Tickets:", agentTickets);
          } catch (error) {
            console.warn("Error fetching agent tickets:", error.message);
          }
        
          // Fetch manager tickets
          try {
            const managerTicketResponse = await fetch(`${MANAGER_TICKETS}/${id}`);
            if (managerTicketResponse.ok) {
              const managerTicketsData = await managerTicketResponse.json();
              managerTickets = managerTicketsData?.meta?.tickets || [];
              console.log("Manager Tickets:", managerTickets);
            } else if (managerTicketResponse.status === 404) {
              console.warn("Manager tickets not found (404).");
            } else {
              throw new Error(`Error fetching manager tickets: ${managerTicketResponse.statusText}`);
            }
          } catch (error) {
            console.warn("Error fetching manager tickets:", error.message);
          }
        
          // Combine and deduplicate tickets
          const combinedTickets = [...agentTickets, ...managerTickets];
          const uniqueTickets = combinedTickets.reduce((acc, current) => {
            if (!acc.some((item) => item.id === current.id)) {
              acc.push(current);
            }
            return acc;
          }, []);
        
          return uniqueTickets;
        } catch (error) {
          console.error("Error in ticket fetching process:", error);
          throw error; // Re-throw for upper-level handling
        }
        
      } else if (role === "child") {
        const response = await fetchChildCompanyTicketsById(id);
        console.log(response)
        if (!response.data.error) {
          return response.data.meta.tickets;
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }
      } else {
        const response = await fetchCompanyTickets(id);
        if (!response.data.error) {
          return response.data.meta.tickets;
        } else if (response.data.error) {
          showToast("error", response.data.message);
        }
      }
    } catch (error) {
      console.error("Error fetching tickets:", error);
      return rejectWithValue(error.message);
    }
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    ticketsLoaded: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTicketsForRole.pending, (state) => {
        state.ticketsLoaded = false;
        state.error = null;
      })
      .addCase(fetchTicketsForRole.fulfilled, (state, action) => {
        state.tickets = action.payload;
        state.ticketsLoaded = true;
        state.error = null;
      })
      .addCase(fetchTicketsForRole.rejected, (state, action) => {
        console.error("Error fetching tickets:", action.payload);
        state.ticketsLoaded = true;
        state.error = action.payload || "Error fetching tickets";
      });
  },
});

export default ticketsSlice.reducer;
