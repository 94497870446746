import React, { useState, useEffect } from "react";
import {
  fetchChildCompanyTicketsById,
  fetchCompanyTickets,
  fetchTicketsForAgent,
  fetchTicketsForEmployee,
} from "../config/webclient";
import MainLayout from "../components/MainLayout";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { COMPANY_GET_EMPLOYEES } from "../config/api";
import axios from "axios";

import { useDispatch, useSelector } from "react-redux";
import { fetchTicketsForRole } from "../tickets/ticketsSlice";
import { showToast } from "../components/global/showToast";

const Reports = () => {
  const [ticketData, setTicketData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [agentFilter, setAgentFilter] = useState("All");
  const [createdFilter, setCreatedFilter] = useState("All");
  const [priorityFilter, setPriorityFilter] = useState("Any");
  const [statusFilter, setStatusFilter] = useState("Any");
  const [responseFilter, setResponseFilter] = useState("Any");
  const role = localStorage.getItem("role");
  const [sortOrder, setSortOrder] = useState("new");
  const companyId = localStorage.getItem("id");

  const dispatch = useDispatch();
  const { tickets, ticketsLoaded } = useSelector((state) => state.tickets);

  useEffect(() => {
    // Fetch tickets based on role and companyId
    if (role && companyId) {
      dispatch(fetchTicketsForRole({ id: companyId, role }));
    }
  }, [dispatch, role, companyId]); // Dependencies to refetch tickets on role or company change

  useEffect(() => {
    // Calculate response time once tickets are loaded and available
    if (ticketsLoaded && tickets.length > 0) {
      const ticketsWithResponseTime = tickets.map((ticket) => {
        const responseTime = calculateBusinessHours(
          ticket.createdAt,
          ticket.firstResponseTime
        );
        return { ...ticket, responseTime }; // Add response time to ticket
      });

      // Update state with calculated data
      setTicketData(ticketsWithResponseTime);
      setFilteredData(ticketsWithResponseTime);
    }
  }, [ticketsLoaded, tickets]); // Only recalculate when tickets or ticketsLoaded change

  // Calculate business hours function
  const calculateBusinessHours = (createdAt, updatedAt) => {
    const startShift = 10; // 10:00 AM
    const endShift = 18; // 6:00 PM
    const oneHour = 60 * 60 * 1000; // milliseconds in one hour

    let created = new Date(createdAt);
    let updated = new Date(updatedAt);

    // Adjust createdAt and updatedAt to working hours
    created = adjustToWorkingHours(created);
    updated = adjustToWorkingHours(updated);

    // Calculate total time difference
    let totalHours = 0;

    while (created < updated) {
      let hours = created.getHours();

      if (hours >= startShift && hours < endShift) {
        // Only count hours within the shift
        let endOfShift = new Date(created);
        endOfShift.setHours(endShift, 0, 0, 0);
        let timeToCount = Math.min(endOfShift - created, updated - created);
        totalHours += timeToCount / oneHour;
      }

      // Move to next day if time has passed shift end
      created.setDate(created.getDate() + 1);
      created.setHours(startShift, 0, 0, 0);
    }

    return totalHours;
  };

  // Adjust time to working hours
  const adjustToWorkingHours = (date) => {
    let hours = date.getHours();
    if (hours < 10) {
      date.setHours(10, 0, 0, 0);
    } else if (hours >= 18) {
      date.setHours(10, 0, 0, 0);
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  // Filter logic
  useEffect(() => {
    let filtered = ticketData;

    if (agentFilter !== "All") {
      const agentFilterNum = Number(agentFilter);

      filtered = filtered.filter((ticket) => {
        return ticket.agent.id === agentFilterNum;
      });

      console.log("Filtered tickets after filtering:", filtered); // Check the filtered array
    }

    if (sortOrder === "old") {
      // Newest first
      filtered.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    } else if (sortOrder === "new") {
      // Oldest first
      filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    // Filter by created date
    if (createdFilter !== "All") {
      const currentTime = new Date();
      filtered = filtered.filter((ticket) => {
        const createdAt = new Date(ticket.createdAt);
        const timeDiff = (currentTime - createdAt) / (1000 * 60); // Convert to minutes

        switch (createdFilter) {
          case "Within 5 minutes":
            return timeDiff <= 5;
          case "Within 15 minutes":
            return timeDiff <= 15;
          case "Within 30 minutes":
            return timeDiff <= 30;
          case "Within 1 hour":
            return timeDiff <= 60;
          case "Within 4 hours":
            return timeDiff <= 240;
          case "Within 12 hours":
            return timeDiff <= 720;
          case "Within 24 hours":
            return timeDiff <= 1440;
          case "Today":
            return (
              createdAt.getDate() === currentTime.getDate() &&
              createdAt.getMonth() === currentTime.getMonth() &&
              createdAt.getFullYear() === currentTime.getFullYear()
            );
          case "Yesterday":
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            return (
              createdAt.getDate() === yesterday.getDate() &&
              createdAt.getMonth() === yesterday.getMonth() &&
              createdAt.getFullYear() === yesterday.getFullYear()
            );
          case "This Week":
            const startOfWeek = new Date();
            startOfWeek.setDate(currentTime.getDate() - currentTime.getDay());
            return createdAt >= startOfWeek;
          case "Last 7 days":
            const last7Days = new Date();
            last7Days.setDate(currentTime.getDate() - 7);
            return createdAt >= last7Days;
          case "This month":
            return (
              createdAt.getMonth() === currentTime.getMonth() &&
              createdAt.getFullYear() === currentTime.getFullYear()
            );
          default:
            return true;
        }
      });
    }

    // Filter by priority
    if (priorityFilter !== "Any") {
      filtered = filtered.filter(
        (ticket) => ticket.priority === priorityFilter
      );
    }

    // Filter by status
    if (statusFilter !== "Any") {
      filtered = filtered.filter((ticket) => ticket.status === statusFilter);
    }

    // Filter by response time
    if (responseFilter !== "Any") {
      filtered = filtered.filter((ticket) => {
        const responseTime = ticket.responseTime; // assuming responseTime is in hours
        switch (responseFilter) {
          case "Less than 1 hour":
            return responseTime < 1;
          case "1 - 2 hours":
            return responseTime >= 1 && responseTime < 2;
          case "2 - 4 hours":
            return responseTime >= 2 && responseTime < 4;
          case "More than 4 hours":
            return responseTime >= 4;
          default:
            return true;
        }
      });
    }

    setFilteredData(filtered);
  }, [
    agentFilter,
    createdFilter,
    priorityFilter,
    statusFilter,
    responseFilter,
    ticketData,
    sortOrder,
  ]);

  // Export to Excel function
  const exportToExcel = () => {
    // Format the data for export
    const formattedData = filteredData.map((ticket) => ({
      "Ticket ID": ticket.id,
      Subject: ticket.title,
      Agent: ticket.agent.name,
      "Created At": new Date(ticket.createdAt).toLocaleString(),
      Status: ticket.status,
      "Response Time": formatResponseTime(ticket.responseTime), // Format response time
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "Reports.xlsx");
  };

  const formatResponseTime = (hours) => {
    // Convert hours to total seconds
    const totalSeconds = Math.round(hours * 3600);

    // Extract hours, minutes, and seconds
    const h = Math.floor(totalSeconds / 3600); // Extract hours
    const m = Math.floor((totalSeconds % 3600) / 60); // Extract minutes
    const s = totalSeconds % 60; // Extract remaining seconds

    let result = "";

    if (h > 0) {
      result += `${h} hour${h > 1 ? "s" : ""} `;
    }
    if (m > 0) {
      result += `${m} min `;
    }
    if (s > 0) {
      result += `${s} sec`;
    }

    return result.trim(); // Trim any extra spaces
  };

  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const fetchAgents = async () => {
      try {
        const companyId = localStorage.getItem("id"); // Fetch company ID from local storage
        if (!companyId) {
          throw new Error("Company ID not found in local storage");
        }
        const response = await axios.get(COMPANY_GET_EMPLOYEES(companyId));
        if (!response.data.error) {
          setAgents(response.data.meta.employees);
          console.log(response.data);
        } else if (response.data.error) {
          showToast("error", response.data.message)
        } 
      } catch (error) {
        showToast("error", error.message)
        console.error("Error fetching agents: " + error.message);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, []);

  return (
    <MainLayout>
      <Typography variant="h6" gutterBottom>
        Analytics
      </Typography>

      <div className="card margin-less-25 no-border">
        <div className="p-2 mb-0 d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 mx-2 f-13">
              <b>Sort</b>
            </p>
            <select
              className="form-select f-13"
              value={sortOrder}
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="new">Newest First</option>
              <option value="old">Oldest First</option>
            </select>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="mb-0 mx-2 f-13">
              <b>Filters</b>
            </p>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">Agent</p>
              <select
                className="my-2 f-13 form-select no-box"
                value={agentFilter}
                onChange={(e) => setAgentFilter(e.target.value)}
              >
                <option value="All">All</option>
                {agents
                  .filter((employee) => employee.designation !== "Owner")
                  .map((agent) => (
                    <option key={agent.id} value={agent.id}>
                      {agent.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">Created</p>
              <select
                className="my-2 f-13 form-select no-box"
                value={createdFilter}
                onChange={(e) => setCreatedFilter(e.target.value)}
              >
                <option>All</option>
                <option>Within 5 minutes</option>
                <option>Within 15 minutes</option>
                <option>Within 30 minutes</option>
                <option>Within 1 hour</option>
                <option>Within 4 hours</option>
                <option>Within 12 hours</option>
                <option>Within 24 hours</option>
                <option>Today</option>
                <option>Yesterday</option>
                <option>This Week</option>
                <option>Last 7 days</option>
                <option>This month</option>
              </select>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">Priority</p>
              <select
                className="my-2 f-13 form-select no-box"
                value={priorityFilter}
                onChange={(e) => setPriorityFilter(e.target.value)}
              >
                <option>Any</option>
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
                <option>Urgent</option>
              </select>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-0 mx-2 f-13">Response Time</p>
              <select
                className="my-2 f-13 form-select no-box"
                value={responseFilter}
                onChange={(e) => setResponseFilter(e.target.value)}
              >
                <option>Any</option>
                <option>Less than 1 hour</option>
                <option>1 - 2 hours</option>
                <option>2 - 4 hours</option>
                <option>More than 4 hours</option>
              </select>
            </div>
          </div>
          <div>
            <Tooltip title="Download Report" arrow placement="right">
              <button
                className="btn btn-primary bg-excel f-14"
                onClick={exportToExcel}
                aria-label="Download Report"
              >
                <i className="fa fa-file-excel" aria-hidden="true"></i>
              </button>
            </Tooltip>
          </div>
        </div>

        <table className="table table-bordered text-center f-12">
          <thead>
            <tr>
              <th>S No.</th>
              <th>#Ticket ID</th>
              <th>Subject</th>
              <th>Agent</th>
              <th>Status</th>
              <th>Priority</th>
              <th>Created Date</th>
              <th>Response</th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 ? (
              filteredData.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>#{item.id}</td>
                  {/* <td>
                          <DateTime createdAt={item.createdAt} />
                        </td> */}
                  <td>{item.title}</td>
                  <td>{item.agent?.name || "N/A"}</td>
                  <td className="text-capitalize">{item.status}</td>
                  <td>
                    <p
                      className={`bg-${item.priority.toLowerCase()}-priority fw-500 mb-0`}
                    >
                      {item.priority}
                    </p>
                  </td>
                  <td>{new Date(item.createdAt).toLocaleString()}</td>
                  <td>{formatResponseTime(item.responseTime)}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No Data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </MainLayout>
  );
};

export default Reports;
