// import React, { useState, useEffect } from "react";
// import { Modal, Box, Button, Typography, TextField } from "@mui/material";
// import {
//   COMPANY_GET_EMPLOYEES,
//   PROJECT_GET_BY_COMPANY_ID,
//   BULK_UPLOAD,
// } from "../config/api";
// import axios from "axios";
// import * as XLSX from "xlsx";
// import { Snackbar } from "@mui/material";

// const UploadModal = ({ open, handleClose }) => {
//   const style = {
//     position: "absolute",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: 400,
//     bgcolor: "background.paper",
//     boxShadow: 24,
//     p: 4,
//     borderRadius: 2,
//   };

//   const [agents, setAgents] = useState([]);
//   const [projects, setProjects] = useState([]);
//   // const [error, setError] = useState(null);
//   const [loading, setLoading] = useState(false);

//   const [file, setFile] = useState(null);
//   const [error, setError] = useState("");
//   const [openSnackbar, setOpenSnackbar] = useState(false);

//   const handleFileChange = (e) => {
//     e.preventDefault()
//     const selectedFile = e.target.files[0];
//     if (
//       selectedFile &&
//       selectedFile.type ===
//         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
//     ) {
//       setFile(selectedFile);
//       setError("");
//     } else {
//       setFile(null);
//       setError("Please upload a valid .xlsx file.");
//       setOpenSnackbar(true);
//     }
//   };

//   const handleUpload = async (e) => {
//     e.preventDefault();
//     if (!file) {
//       setError("No file selected or invalid file format.");
//       setOpenSnackbar(true);
//       return;
//     }

//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       const response = await axios.post(BULK_UPLOAD, formData, {
//         headers: {
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       console.log("Upload success:", response.data);
//       setOpenSnackbar(true);
//     } catch (error) {
//       console.error("Upload error:", error);
//       setError("Failed to upload file.");
//       setOpenSnackbar(true);
//     }
//   };

//   const handleCloseSnackbar = () => {
//     setOpenSnackbar(false);
//   };

//   // Fetch agents and projects when component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       try {
//         const companyId = localStorage.getItem("id");
//         if (!companyId)
//           throw new Error("Company ID not found in local storage");

//         // Fetch agents
//         const agentsResponse = await axios.get(
//           COMPANY_GET_EMPLOYEES(companyId)
//         );
//         setAgents(agentsResponse.data);

//         // Fetch projects
//         const projectsResponse = await axios.get(
//           PROJECT_GET_BY_COMPANY_ID(companyId)
//         );
//         setProjects(projectsResponse.data);
//       } catch (error) {
//         setError("Error fetching data: " + error.message);
//         console.error(error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   // Create and download the XLSX file for projects and agents
//   const downloadXLSX = (e) => {
//     e.preventDefault();
//     const projectData = projects.map((project) => ({
//       "Project Id": project.id,
//       "Project Name": project.name,
//       "Project Description": project.description,
//       "Module Id":
//         project.projectModules.length > 0
//           ? project.projectModules[0].id
//           : "N/A",
//       "Module Name":
//         project.projectModules.length > 0
//           ? project.projectModules[0].name
//           : "N/A",
//       "Module Description":
//         project.projectModules.length > 0
//           ? project.projectModules[0].description
//           : "N/A",
//     }));

//     const agentData = agents.map((agent) => ({
//       "Agent Id": agent.id,
//       "Agent Name": agent.name,
//       "Agent Email": agent.email,
//       "Agent Mobile": agent.mobile || "N/A", // Ensure this field exists in your agent data
//     }));

//     if (projectData.length === 0 && agentData.length === 0) {
//       setError("No data available for download.");
//       return;
//     }

//     // Combine the data for the XLSX file
//     const combinedData = [...projectData, ...agentData];

//     const worksheet = XLSX.utils.json_to_sheet(combinedData);
//     const workbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(workbook, worksheet, "Projects & Agents");
//     XLSX.writeFile(workbook, "projects_agents.xlsx");
//   };

//   if (error) return <Typography color="error">{error}</Typography>;

//   return (
//     <Modal
//       open={open}
//       onClose={handleClose}
//       aria-labelledby="upload-modal-title"
//       aria-describedby="upload-modal-description"
//     >
//       <Box sx={style}>
//         <p className="fw-500">Bulk Upload</p>
//         <Box component="form" sx={{ mt: 2 }}>
//           <form onSubmit={handleUpload}>
//             <div className="d-flex justify-content-between box-file p-2">
//               <input className="w-90" type="file" onChange={handleFileChange} />
//               <button type="submit" className="btn btn-primary f-14">
//                 Upload
//               </button>
//             </div>
//           </form>
//           <Snackbar
//             open={openSnackbar}
//             autoHideDuration={4000}
//             onClose={handleCloseSnackbar}
//             message={error ? error : "File uploaded successfully"}
//           />

//           <button
//             className="btn btn-secondary f-14 my-3 w-100"
//             onClick={downloadXLSX}
//           >
//             Download Projects & Agents
//           </button>
//           {/* <Button
//             variant="contained"
//             color="primary"
//             onClick={downloadXLSX}
//             sx={{ mt: 2, width: "100%", fontSize:"14px" }}
//           >
//             Download Projects & Agents XLSX
//           </Button> */}
//         </Box>
//         <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
//           <Button variant="outlined" onClick={handleClose}>
//             Close
//           </Button>
//         </Box>
//       </Box>
//     </Modal>
//   );
// };

// export default UploadModal;


import React, { useState, useEffect } from "react";
import { Modal, Box, Button, Typography } from "@mui/material";
import {
  COMPANY_GET_EMPLOYEES,
  PROJECT_GET_BY_COMPANY_ID,
  BULK_UPLOAD,
} from "../config/api";
import axios from "axios";
import * as XLSX from "xlsx";
import { Snackbar } from "@mui/material";

const UploadModal = ({ open, handleClose }) => {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const [agents, setAgents] = useState([]);
  const [projects, setProjects] = useState([]);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Handle file selection and ensure it's an .xlsx file
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setFile(selectedFile);
      setError("");
    } else {
      setFile(null);
      setError("Please upload a valid .xlsx file.");
      setSnackbarMessage("Please upload a valid .xlsx file.");
      setOpenSnackbar(true);
    }
  };

  // Handle file upload to the server
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!file) {
      setError("No file selected or invalid file format.");
      setSnackbarMessage("No file selected or invalid file format.");
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(BULK_UPLOAD, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSnackbarMessage("File uploaded successfully");
      setOpenSnackbar(true);
    } catch (error) {
      setSnackbarMessage("Failed to upload file.");
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const companyId = localStorage.getItem("id");
        if (!companyId)
          throw new Error("Company ID not found in local storage");

        const agentsResponse = await axios.get(
          COMPANY_GET_EMPLOYEES(companyId)
        );
        setAgents(agentsResponse.data);

        const projectsResponse = await axios.get(
          PROJECT_GET_BY_COMPANY_ID(companyId)
        );
        setProjects(projectsResponse.data);
      } catch (error) {
        setError("Error fetching data: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Create and download the XLSX file for projects and agents
  const downloadXLSX = (e) => {
    e.preventDefault();

    const projectData = projects.map((project) => ({
      "Project Id": project.id,
      "Project Name": project.name,
      "Project Description": project.description,
      "Module Id":
        project.projectModules.length > 0
          ? project.projectModules[0].id
          : "N/A",
      "Module Name":
        project.projectModules.length > 0
          ? project.projectModules[0].name
          : "N/A",
      "Module Description":
        project.projectModules.length > 0
          ? project.projectModules[0].description
          : "N/A",
    }));

    const agentData = agents.map((agent) => ({
      "Agent Id": agent.id,
      "Agent Name": agent.name,
      "Agent Email": agent.email,
      "Agent Mobile": agent.mobile || "N/A",
    }));

    if (projectData.length === 0 && agentData.length === 0) {
      setError("No data available for download.");
      setSnackbarMessage("No data available for download.");
      setOpenSnackbar(true);
      return;
    }

    const combinedData = [...projectData, ...agentData];
    const worksheet = XLSX.utils.json_to_sheet(combinedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Projects & Agents");
    XLSX.writeFile(workbook, "projects_agents.xlsx");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="upload-modal-title"
      aria-describedby="upload-modal-description"
    >
      <Box sx={style}>
        <Typography className="fw-500">Bulk Upload</Typography>
        <Box sx={{ mt: 2 }}>
          <div className="d-flex justify-content-between box-file p-2">
            <input
              className="w-90"
              type="file"
              onChange={handleFileChange}
            />
            <button
              className="btn btn-primary f-14"
              onClick={handleUpload}
            >
              Upload
            </button>
          </div>
          <Snackbar
            open={openSnackbar}
            autoHideDuration={4000}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
          />

          <button
            className="btn btn-secondary f-14 my-3 w-100"
            onClick={downloadXLSX}
          >
            Download Projects & Agents
          </button>
        </Box>
        <Box sx={{ mt: 3, display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadModal;
