import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Reports from "./pages/Reports";
import Login from "./pages/Login";
import NewTicket from "./pages/NewTicket";
import Tickets from "./pages/Tickets";
import CreateCompany from "./pages/Superadmin/CreateCompany";
import AdminDashboard from "./pages/Company/AdminDashboard";
import SuperadminDashboard from "./pages/Superadmin/SuperadminDashboard";
import "./App.css";
import ViewAllCompany from "./pages/Superadmin/ViewAllCompany";
import CreateAgent from "./pages/Company/CreateAgent";
import Agents from "./pages/Company/Agents";
import CreateProject from "./pages/Company/CreateProject";
import Projects from "./pages/Company/Projects";
import AdminNewTicket from "./pages/Company/AdminNewTicket";
import ChatLogs from "./pages/Company/ChatLogs";
import ChildDashboard from "./pages/child/ChildDashboard";
import Profile from "./pages/Profile";
import AssignAgent from "./pages/AssignAgent";
import { ToastContainer } from "react-toastify";

function ProtectedRoute({ children, allowedRoles }) {
  const location = useLocation();
  const role = localStorage.getItem("role");

  if (!role) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
}

export default function App() {
  return (
    <>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />

      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />

          {/* Superadmin Routes */}
          <Route
            path="/superadmin/*"
            element={
              <ProtectedRoute allowedRoles={["superadmin"]}>
                <Routes>
                  <Route path="dashboard" element={<SuperadminDashboard />} />
                  <Route path="create-company" element={<CreateCompany />} />
                  <Route path="company" element={<ViewAllCompany />} />
                  <Route path="profile" element={<Profile />} />
                  {/* Add more superadmin routes here if needed */}
                </Routes>
              </ProtectedRoute>
            }
          />

          {/* Parent Routes */}
          <Route
            path="/company/*"
            element={
              <ProtectedRoute allowedRoles={["parent", "child"]}>
                {/* <ProtectedRoute allowedRoles={["parent"]}> */}
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="tickets" element={<Tickets />} />
                  <Route path="new-ticket" element={<NewTicket />} />
                  <Route path="create-agent" element={<CreateAgent />} />
                  <Route path="create-project" element={<CreateProject />} />
                  <Route path="create-company" element={<CreateCompany />} />
                  <Route path="company" element={<ViewAllCompany />} />
                  <Route path="agents" element={<Agents />} />
                  <Route path="projects" element={<Projects />} />
                  <Route path="create-ticket" element={<AdminNewTicket />} />
                  <Route path="ticket/:id" element={<ChatLogs />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="reports" element={<Reports />} />
                  <Route path="assign-agent" element={<AssignAgent />} />
                  {/* Add more parent routes here if needed */}
                </Routes>
              </ProtectedRoute>
            }
          />

          {/* Employee Routes */}
          <Route
            path="/employee/*"
            element={
              <ProtectedRoute allowedRoles={["employee", "agent", "manager"]}>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="new-ticket" element={<NewTicket />} />
                  <Route path="tickets" element={<Tickets />} />
                  <Route path="ticket/:id" element={<ChatLogs />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="assign-agent" element={<AssignAgent />} />
                  {/* Add more employee routes here if needed */}
                </Routes>
              </ProtectedRoute>
            }
          />
          <Route
            path="/user/*"
            element={
              <ProtectedRoute allowedRoles={["user"]}>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="new-ticket" element={<NewTicket />} />
                  <Route path="tickets" element={<Tickets />} />
                  <Route path="ticket/:id" element={<ChatLogs />} />
                  <Route path="profile" element={<Profile />} />
                  {/* Add more employee routes here if needed */}
                </Routes>
              </ProtectedRoute>
            }
          />
          <Route
            path="/child/*"
            element={
              <ProtectedRoute allowedRoles={["child"]}>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="tickets" element={<Tickets />} />
                  <Route path="create-agent" element={<CreateAgent />} />
                  <Route path="agents" element={<Agents />} />
                  <Route path="ticket/:id" element={<ChatLogs />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="new-ticket" element={<NewTicket />} />
                </Routes>
              </ProtectedRoute>
            }
          />

          {/* Default Route */}
          <Route path="/" element={<Navigate to="/login" />} />
        </Routes>
      </Router>
    </>
  );
}
