import React, { useEffect, useState } from "react";
import MainLayout from "../../components/MainLayout";
import Chart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import DateTime from "../../components/DateTime";

import { useDispatch, useSelector } from "react-redux";
import { fetchTicketsForRole } from "../../tickets/ticketsSlice";

const AdminDashboard = () => {
  const role = localStorage.getItem("role");
  const id = localStorage.getItem("id");
  const [ticketCounts, setTicketCounts] = useState({
    pending: 0,
    closed: 0,
    reopen: 0,
    open: 0,
    resolved: 0,
    unassigned: 0,
  });

  const [priorityData, setPriorityData] = useState({
    low: 0,
    medium: 0,
    high: 0,
    urgent: 0,
  });

  const cardsItem = [
    {
      item: "Pending",
      content: ticketCounts.pending || 0,
      card: "col-md-6",
      path: "pending",
    },
    {
      item: "Closed",
      content: ticketCounts.closed || 0,
      card: "col-md-6",
      path: "closed",
    },
    {
      item: "Reopen",
      content: ticketCounts.reopen || 0,
      card: "col-md-6",
      path: "reopen",
    },
    {
      item: "Open",
      content: ticketCounts.open || 0,
      card: "col-md-6",
      path: "open",
    },
    {
      item: "Resolved",
      content: ticketCounts.resolved || 0,
      card: role === "parent" || role === "employee" ? "col-md-6" : "col-md-12",
      path: "resolved",
    },
    {
      item: "Unassigned",
      content: ticketCounts.unassigned || 0,
      card: role === "parent" || role === "employee" ? "col-md-6" : "col-md-12",
      path: "unassigned",
    },
  ];

  const chartOptions = {
    chart: {
      height: 350,
      type: "bar",
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Tickets",
        data: [
          ticketCounts.pending,
          ticketCounts.closed,
          ticketCounts.reopen,
          ticketCounts.open,
          ticketCounts.resolved,
          // ticketCounts.unassigned,
        ],
      },
    ],
    xaxis: {
      categories: [
        "Pending",
        "Closed",
        "Reopen",
        "Open",
        "Resolved",
        // "Unassigned",
      ],
      title: {
        text: "Ticket Status",
      },
    },
    yaxis: {
      title: {
        text: "Count",
      },
    },
    colors: ["#015C92", "#01b3b5", "#00A6ED", "#88CDF6", "#BCE6FF"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
    },
  };

  const priorityChartOptions = {
    chart: {
      height: 350,
      type: "bar",
      stacked: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        distributed: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Priority",
        data: [
          priorityData.low,
          priorityData.medium,
          priorityData.high,
          priorityData.urgent,
        ],
      },
    ],
    xaxis: {
      categories: ["Low", "Medium", "High", "Urgent"],
    },

    colors: ["#a0d76a", "#4da1ff", "#ffd012", "#ff5959"],
    tooltip: {
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      floating: true,
    },
  };

  const navigate = useNavigate();
  const navigateTicket = (path) => {
    if (role === "parent") {
      navigate(`/company/tickets?status=${path}`);
    } else {
      navigate(`/${role}/tickets?status=${path}`);
    }
  };

  const [ticketData, setTicketData] = useState([]);

  const dispatch = useDispatch();
  const { tickets, ticketsLoaded, error } = useSelector(
    (state) => state.tickets
  );

  useEffect(() => {
    if (role && id) {
      dispatch(fetchTicketsForRole({ id: id, role: role }));
    }
  }, [dispatch, role, id]);

  useEffect(() => {
    if (ticketsLoaded && tickets.length > 0) {
      console.log("Fetched tickets:", tickets);

      // Initialize counters for different statuses
      let pendingCount = 0;
      let closedCount = 0;
      let reopenCount = 0;
      let openCount = 0;
      let resolvedCount = 0;
      let unassignedTickets = 0;

      let low = 0;
      let medium = 0;
      let high = 0;
      let urgent = 0;

      // Create a copy of tickets if necessary (though for iteration it's safe to use the original array)
      const ticketsCopy = [...tickets];

      // Count tickets by status
      ticketsCopy.forEach((ticket) => {
        switch (ticket.status) {
          case "pending":
            pendingCount++;
            break;
          case "closed":
            closedCount++;
            break;
          case "reopen":
            reopenCount++;
            break;
          case "open":
            openCount++;
            break;
          case "resolved":
            resolvedCount++;
            break;
          default:
            break;
        }

        // Count unassigned tickets
        if (!ticket.agent || ticket.agent === null) {
          unassignedTickets++;
        }
      });

      // Count tickets by priority
      ticketsCopy.forEach((ticket) => {
        switch (ticket.priority?.toLowerCase()) {
          case "low":
            low++;
            break;
          case "medium":
            medium++;
            break;
          case "high":
            high++;
            break;
          case "urgent":
            urgent++;
            break;
          default:
            break;
        }
      });

      // Update state with the ticket counts
      setTicketCounts({
        pending: pendingCount,
        closed: closedCount,
        reopen: reopenCount,
        open: openCount,
        resolved: resolvedCount,
        unassigned: unassignedTickets,
      });

      setPriorityData({
        low: low,
        medium: medium,
        high: high,
        urgent: urgent,
      });
    }


    const priorities = { Low: 0, Medium: 0, High: 0, Urgent: 0 };
    const tableDataArray = [];

    tickets.forEach((ticket) => {
      const responseHours = calculateBusinessHours(
        ticket.createdAt,
        ticket.firstResponseTime
      );
      priorities[ticket.priority] += responseHours;
      tableDataArray.push({
        title: ticket.title,
        createdAt: formatDateTime(ticket.createdAt),
        updatedAt: formatDateTime(ticket.updatedAt),
        responseHours: responseHours,
        priority: ticket.priority,
      });
    });

    setTableData(tableDataArray);
    setChartData([
      priorities.Low,
      priorities.Medium,
      priorities.High,
      priorities.Urgent,
    ]);
  }, [tickets, ticketsLoaded]);

  const pageNavigate = () => {
    if (role === "parent") {
      navigate("/company/tickets");
    } else if (role === "child") {
      navigate("/child/tickets");
    } else {
      navigate(`/${role}/tickets`);
    }
  };

  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const calculateBusinessHours = (createdAt, updatedAt) => {
    const startShift = 10;
    const endShift = 18;
    const oneHour = 60 * 60 * 1000;

    let created = new Date(createdAt);
    let updated = new Date(updatedAt);

    created = adjustToWorkingHours(created);
    updated = adjustToWorkingHours(updated);

    let totalHours = 0;

    while (created < updated) {
      let hours = created.getHours();

      if (hours >= startShift && hours < endShift) {
        let endOfShift = new Date(created);
        endOfShift.setHours(endShift, 0, 0, 0);
        let timeToCount = Math.min(endOfShift - created, updated - created);
        totalHours += timeToCount / oneHour;
      }

      created.setDate(created.getDate() + 1);
      created.setHours(startShift, 0, 0, 0);
    }

    return totalHours;
  };

  const adjustToWorkingHours = (date) => {
    let hours = date.getHours();
    if (hours < 10) {
      date.setHours(10, 0, 0, 0);
    } else if (hours >= 18) {
      date.setHours(10, 0, 0, 0);
      date.setDate(date.getDate() + 1);
    }
    return date;
  };

  const formatDateTime = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")} ${date
      .getHours()
      .toString()
      .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
  };

  const chartPieOptions = {
    chart: {
      type: "pie",
      height: 100,
    },
    labels: ["Low", "Medium", "High", "Urgent"],
    colors: ["#a0d76a", "#4da1ff", "#ffd012", "#ff5959"], 
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const formatResponseTime = (hours) => {
    const totalSeconds = Math.round(hours * 3600);
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = totalSeconds % 60;

    let result = "";

    if (h > 0) {
      result += `${h} hour${h > 1 ? "s" : ""} `;
    }
    if (m > 0) {
      result += `${m} min `;
    }
    if (s > 0) {
      result += `${s} sec`;
    }

    return result.trim();
  };

  const totalResponseTime = tableData.reduce(
    (total, item) => total + item.responseHours,
    0
  );
  const averageResponseTime = totalResponseTime / (tableData.length || 1);
  console.log(
    formatResponseTime(totalResponseTime),
    formatResponseTime(averageResponseTime)
  );

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const todayTickets = tableData.filter((item) =>
    isToday(new Date(item.createdAt))
  );
  const todayAverageResponseTime =
    totalResponseTime / (todayTickets.length || 1);
  return (
    <MainLayout>
      <div className="row">
        <div className="col-md-6">
          <div className="row">
            {cardsItem.map((item, index) => (
              <div
                key={index}
                className={`my-2 ${item.card}`}
                onClick={() => navigateTicket(item.path)}
              >
                <div className="card p-3 box-card">
                  <p className="f-14 mb-1">{item.item}</p>
                  <h4
                    className={
                      item.content === "0"
                        ? "text-bolder text-grey"
                        : "text-bolder"
                    }
                  >
                    {item.content}
                  </h4>
                </div>
              </div>
            ))}

            {role === "parent" || role === "employee" ? (
              <>
                <div class="my-2 col-md-6">
                  <div class="card p-3 box-card">
                    <p class="f-14 mb-1">Average Response Time</p>
                    <h4 class="text-bolder">
                      {formatResponseTime(averageResponseTime)}
                    </h4>
                  </div>
                </div>
                <div class="my-2 col-md-6">
                  <div class="card p-3 box-card">
                    <p class="f-14 mb-1">Today Average Response Time</p>
                    <h4 class="text-bolder">
                      {formatResponseTime(todayAverageResponseTime)}
                    </h4>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="col-md-6 my-2">
          <div className="card p-3 box-main-card">
            <p>Ticket Activity</p>
            <div className="row align-items-center">
              <div className="col-md-12">
                <Chart
                  options={chartOptions}
                  series={chartOptions.series}
                  type="bar"
                  height={335}
                />
              </div>
            </div>
          </div>
        </div>

        {/* new updated ui */}
        <>
          <div className="col-md-6 my-3">
            <div className="d-flex justify-content-between">
              <h5 className="fw-500">Tickets</h5>
              <button
                className="btn f-13 no-box-shadow btn-hover"
                onClick={() => pageNavigate()}
              >
                View all Tickets ({tickets.length > 0 ? tickets.length : "0"})
              </button>
            </div>
            <table className="table table-bordered text-center f-12">
              <thead>
                <tr>
                  <th>#Ticket ID</th>
                  <th>Date</th>
                  <th>Subject</th>
                  <th>Agent</th>
                  <th>Status</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {tickets.length > 0 ? (
                  [...tickets] // Create a shallow copy of the tickets array
                    .sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    ) // Safe to sort now
                    .slice(-5) // Get the last 5 tickets
                    .map((item) => (
                      <tr key={item.id}>
                        <td>#{item.id}</td>
                        <td>
                          <DateTime createdAt={item.createdAt} />
                        </td>
                        <td>{item.title}</td>
                        <td>{item.agent?.name || "N/A"}</td>
                        <td className="text-capitalize">{item.status}</td>
                        <td>
                          <p
                            className={`bg-${item.priority.toLowerCase()}-priority fw-500 mb-0`}
                          >
                            {item.priority}
                          </p>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan="6">No tickets available</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {role === "child" || role === "user" ? (
            <div className="col-md-6 my-3">
              <h5>Ticket Priority</h5>
              <div className="card">
                <Chart
                  options={priorityChartOptions}
                  series={priorityChartOptions.series}
                  type="bar"
                  height={223}
                />
              </div>
            </div>
          ) : (
            <div className="col-md-6">
              <h5>Response Time</h5>
              <div className="card">
                <Chart
                  options={chartPieOptions}
                  series={chartData}
                  type="pie"
                  width="500"
                  height={250}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </MainLayout>
  );
};

export default AdminDashboard;
