import React from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';

function TimeAgo({ createdAt }) {
  const date = parseISO(createdAt);
  const timeAgo = formatDistanceToNow(date, { addSuffix: true });

  return <span>{timeAgo}</span>;
} 

export default TimeAgo;
